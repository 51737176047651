import { members } from "../../js/path";

export default {
  data() {
    return {
      social_links_form: {
        languages: "",
      },
      member_social_links: {},
      social_links: null,
      custom_badge_icon_url: null,
      edit: {
        custom_badge_icon_url: null,
      },
    };
  },
  methods: {
    readFile(e, txt) {
      if (txt == "custom_badge_icon") {
        this.custom_badge_icon = e.target.files[0];
        if (this.custom_badge_icon.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is more than 2MB",
          });
          this.$refs.custom_badge_icon.reset();
          this.custom_badge_icon_url = "";
          this.edit.custom_badge_icon_url = "";
          this.custom_badge_icon = "";
          return false;
        } else {
          this.custom_badge_icon_url = URL.createObjectURL(
            this.custom_badge_icon
          );
          this.edit.custom_badge_icon_url = "";
        }
      }
    },
    checkSocialLinkValidity() {
      const validUrlRegex = /^(ftp|http|https):\/\/[^ .]+\.[^ "]+$/;
      for (const [key, value] of Object.entries(this.social_links_form)) {
        if (value !== "" && value !== null && key !== "languages") {
          if (!validUrlRegex.test(value)) {
            let fieldName = key.charAt(0).toUpperCase() + key.slice(1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: `Invalid ${fieldName} link. Please enter a valid URL.`,
            });
            return false;
          }
        }
      }

      return true;
    },
    async getSocialLinks() {
      try {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getSocialLinks;
        const res = await this.getRequest(url);
        if (res.status) {
          this.social_links = res.response;
        }
        this.social_links.forEach((element) => {
          this.social_links_form[element.value] = "";
        });

        if (this.member_social_links !== null) {
          Object.entries(this.member_social_links).forEach(([key, value]) => {
            if (key in this.social_links_form) {
              if (value === "null") {
                this.social_links_form[key] = null;
              } else {
                this.social_links_form[key] = value;
              }
            }
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitSocialLinks() {
      let validateForm = this.checkSocialLinkValidity();
      if (!validateForm) {
        return;
      } else {
        this.$store.commit("loader/updateStatus", true);
        try {
          const dataAppend = new FormData();
          for (const key in this.social_links_form) {
            if (key !== "languages") {
              if (Object.hasOwnProperty.call(this.social_links_form, key)) {
                const value =
                  this.social_links_form[key] === null
                    ? ""
                    : this.social_links_form[key];
                dataAppend.append(`social_links[${key}]`, value);
              }
            }
          }
          dataAppend.append("languages", this.social_links_form.languages);
          dataAppend.append("custom_badge_icon", this.custom_badge_icon);
          const url = `${members.addSocialLinks}?member_id=${this.$route.params.id}`;
          const res = await this.postRequest(url, dataAppend);

          if (res.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: res.message,
            });
          }
          this.getMemberSocialLinks();
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async getMemberSocialLinks() {
      try {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMemberSocialLinks + "/" + this.$route.params.id;
        const res = await this.getRequest(url);
        if (res.response !== null) {
          this.member_social_links = res.response.social_links;
          this.edit.custom_badge_icon_url = res.response.custom_badge_icon;

          if (res.response.languages !== null && res.response.laguage !== "") {
            this.social_links_form.languages = res.response.languages;
          }
        } else {
          this.member_social_links = null;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    member_social_links: {
      handler: function () {
        this.getSocialLinks();
      },
    },
  },
  created() {
    this.getMemberSocialLinks();
  },
};
